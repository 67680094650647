import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface DialogButton<T> {
    label: string;
    cssClass: string;
    returnValue: T;
}

@Component({
    selector: 'telxl-dialog-modal',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './dialog.component.html',
    styleUrl: './dialog.component.scss',
})
export class TelXLDialogModalComponent<T> {
    activeModal = inject(NgbActiveModal);

    @Input({ required: true }) title!: string;
    @Input({ required: true }) icon!: string;
    @Input({ required: true }) message!: string;
    @Input({ required: true }) buttons!: DialogButton<T>[];

    @Output() dialogResult = new EventEmitter<T>();

    handleButtonClick(returnValue: T): void {
        this.dialogResult.emit(returnValue);
    }
}
