import { IAssignToMe, ICompleteWorkItemParams } from './../../models';
import { Injectable, computed, signal } from '@angular/core';
import { Observable, tap } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { LoggerService } from './../../services';
import { environment } from './../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AgentApiService {
    private _unableToAssign = signal<string[]>([]);
    unableToAssign = computed<string[]>(() =>
        this._unableToAssign.asReadonly()(),
    );

    constructor(
        private logger: LoggerService,
        private http: HttpClient,
    ) {}

    reserveForLookup() {
        this.http
            .post<string>(
                environment.blenderUrl + '/resource/reserveForLookup',
                null,
            )
            .subscribe({
                next: () => {
                    this.logger.debug(
                        'Agent Service (Look up activated) -> Successful',
                    );
                },
                error: error => {
                    this.logger.error(
                        'Agent Service (Look up activated) -> Failed',
                        error,
                    );
                },
            });
    }

    assignToMe(conversationId: string): Observable<IAssignToMe> {
        return this.http
            .post<IAssignToMe>(
                environment.blenderUrl +
                    `/resource/${conversationId}/assignToMe`,
                null,
            )
            .pipe(
                tap({
                    next: result => {
                        this.logger.debug(
                            'Agent Service (Conversation assigned to Agent) -> Successful',
                        );

                        if (result.result !== 'Ok') {
                            this._unableToAssign.update(u => [
                                ...u,
                                conversationId,
                            ]);
                        }
                    },
                    error: error => {
                        this.logger.error(
                            'Agent Service (Conversation assigned to Agent) -> Failed',
                            error,
                        );
                    },
                }),
            );
    }

    resetWrapup(workitemId: string) {
        this.http
            .post<void>(
                environment.blenderUrl +
                    `/resource/resetwrapup?workitemId=${workitemId}`,
                null,
            )
            .subscribe({
                next: () => {
                    this.logger.debug(
                        'Agent Service (Reset wrapup) -> Successful',
                    );
                },
                error: error => {
                    this.logger.error(
                        'Agent Service (Reset wrapup) -> Failed',
                        error,
                    );
                },
            });
    }

    completeWorkItem(params: ICompleteWorkItemParams) {
        this.http
            .post<void>(
                environment.blenderUrl + '/resource/completeWorkItem',
                params,
            )
            .subscribe({
                next: () => {
                    this.logger.debug(
                        'Agent Service (Work item completed) -> Successful',
                    );
                },
                error: error => {
                    this.logger.error(
                        'Agent Service (Work item completed) -> Failed',
                        error,
                    );
                },
            });
    }
}
