<div class="container-fluid">
    <div
        class="fade-transition"
        [ngClass]="{ active: presence().state === 'Break' }"
    >
        @if (presence().state === 'Break') {
            <div class="row">
                <div class="col-12">
                    <app-shell-main-layout-break />
                </div>
            </div>
        }
    </div>
    <div
        class="fade-transition"
        [ngClass]="{ active: presence().state !== 'Break' }"
    >
        @if (presence().state !== 'Break') {
            <div class="row">
                <div class="col-12 ps-2 pe-3">
                    <div class="row mb-2 mt-2">
                        <app-shell-task-belt />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="main-container">
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </div>
        }
    </div>
</div>
