import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { DoughnutItem, TelXLDoughnutComponent } from '@dxp/elements';
import {
    findStateForMetric,
    leftSplit,
    rightSplit,
    secondsToDuration,
} from '@dxp/shared/helpers';
import { IMetric, IValueWithMetric, MediaType } from '@dxp/shared/models';
import { FormatDurationPipe } from '@dxp/shared/pipes';

@Component({
    selector: 'telxl-shared-channel-type-panel',
    standalone: true,
    imports: [CommonModule, FormatDurationPipe, TelXLDoughnutComponent],
    templateUrl: './channel-type-panel.component.html',
    styleUrl: './channel-type-panel.component.scss',
})
export class TelXLChannelTypeComponent {
    selectedMediaType: MediaType = 'Voice';
    secondsToDuration = secondsToDuration;
    DoughnutItem = DoughnutItem;

    @Input({ required: true }) channelTypes!: Record<
        MediaType,
        {
            split: number;
            handledCount: number;
            missedCount: number;
            averageHandlingTimeInSeconds: IValueWithMetric;
            ratio: number;
        }
    >;

    @Input({ required: true }) chartSize!: string;
    @Input({ required: true }) chartSplit!: number;

    selectChannelType(item: MediaType) {
        this.selectedMediaType = item;
    }

    getIconClass(item: MediaType): string {
        if (item !== this.selectedMediaType) return 'fa-light';

        switch (item) {
            case 'Voice':
                return 'fa-solid text-success';
            case 'Email':
                return 'fa-solid text-email';
            case 'Webchat':
                return 'fa-solid text-webchat';
            case 'Messaging':
                return 'fa-solid text-socials';
        }
    }

    getThresholdClass(metric: IMetric): string {
        return `text-${findStateForMetric(metric, 'success', 'dark')}`;
    }

    get metric(): IMetric {
        const total =
            this.channelTypes.Email.handledCount +
            this.channelTypes.Email.missedCount +
            this.channelTypes.Voice.handledCount +
            this.channelTypes.Voice.missedCount +
            this.channelTypes.Webchat.handledCount +
            this.channelTypes.Webchat.missedCount +
            this.channelTypes.Messaging.handledCount +
            this.channelTypes.Messaging.missedCount;

        return {
            percent: total > 0 ? Math.round((this.value / total) * 100) : 0,
            states: [{ value: 0, state: this.state }],
        };
    }

    get state(): 'success' | 'email' | 'socials' | 'webchat' {
        switch (this.selectedMediaType) {
            case 'Voice':
                return 'success';
            case 'Email':
                return 'email';
            case 'Messaging':
                return 'socials';
            case 'Webchat':
                return 'webchat';
        }
    }

    get value(): number {
        return (
            this.channelTypes[this.selectedMediaType].handledCount +
            this.channelTypes[this.selectedMediaType].missedCount
        );
    }

    get leftSplit(): number {
        return leftSplit(this.chartSplit);
    }

    get rightSplit(): number {
        return rightSplit(this.leftSplit);
    }

    get splitchart(): string {
        return this.leftSplit === 0 ? 'd-none' : `w-${this.leftSplit}`;
    }
}
