import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColourPaletteType, SizeType } from '@dxp/shared/models';
import { NgbDropdownModule, Placement } from '@ng-bootstrap/ng-bootstrap';

export interface IDropdown<T> {
    label: string;
    value: T;
    disabled: boolean;
}

@Component({
    selector: 'telxl-dropdown',
    imports: [CommonModule, NgbDropdownModule],
    standalone: true,
    templateUrl: './dropdown.component.html',
    styleUrls: [
        './dropdown.component.scss',
        './../button/button.component.primary.scss',
        './../button/button.component.secondary.scss',
        './../button/button.component.success.scss',
        './../button/button.component.warning.scss',
        './../button/button.component.info.scss',
        './../button/button.component.light.scss',
        './../button/button.component.danger.scss',
        './../button/button.component.dark.scss',
        './../button/button.component.dropdown.scss',
    ],
    host: {
        '[class]': 'class || ""',
    },
})
export class TelXLDropdownComponent<T> {
    @Input({ required: true }) menuItems: IDropdown<T>[] = [];
    @Input({ required: true }) colour!: ColourPaletteType;
    @Input({ required: true }) selected!: T;
    @Input() size: SizeType | null = null;
    @Input() placement: Placement = 'bottom-start';
    @Input() outline = false;
    @Input() disabled = false;
    @Input() class = '';
    @Input() text = '';
    @Input() placeholder = '';
    @Input() maxHeight = '100vh';

    @Output() selectedChange = new EventEmitter<T>();

    setSelected(selected: T) {
        this.selectedChange.emit(selected);
    }

    isSelected(selected: T) {
        return this.selected === selected;
    }

    handleKeyPress(event: KeyboardEvent, selected: T) {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            event.stopPropagation();
            this.setSelected(selected);
        }
    }

    get label(): string {
        if (this.text.length > 0) return this.text;

        return (
            this.menuItems.find(i => i.value === this.selected)?.label ??
            this.placeholder
        );
    }

    get dropdownClass(): string {
        let dropdownClass = 'text-start w-100 h-100 btn btn-';

        if (this.outline) dropdownClass += 'outline-';
        dropdownClass += this.colour;

        if (this.size) {
            dropdownClass += ` btn-${this.size}`;
        }

        return dropdownClass;
    }

    dropdownItemClass(item: IDropdown<T>): string {
        let dropdownClass = 'btn';

        if (this.size) {
            dropdownClass += ` btn-${this.size}`;
        }

        if (this.isSelected(item.value)) {
            dropdownClass += ` btn-${this.outline ? 'outline-' : ''}${this.colour}`;
        }

        return dropdownClass;
    }
}
