<div
    class="card px-0 ms-2 task-item"
    [class.selectable]="!displayTaskButtons"
    [ngClass]="isSelected ? task.classSelected : ''"
    [ngClass]="{ 'task-voice': task.mediaType === 'Voice' }"
    (click)="onClick()"
    (keydown.enter)="displayTaskButtons ? onClick() : null"
    tabindex="0"
>
    <div class="card-body py-0 px-0">
        <div class="d-flex">
            <div class="ms-2 me-2">
                <i [ngClass]="isSelected ? task.iconSelected : task.icon"></i>
            </div>
            <div
                class="ms-1"
                [ngClass]="{ 'task-detail-width': displayTaskButtons }"
            >
                <div class="task-name">
                    {{ task.mediaType }}
                </div>
                <div class="task-description pe-1">
                    {{ task.queueName }}
                </div>
                <div class="task-time mt-1">
                    {{ task.createdAt }}
                </div>
            </div>
            @if (displayTaskButtons) {
                <div class="flex-grow-1 text-end call-buttons-container">
                    <div class="me-2 mt-1">
                        <button
                            class="btn btn-xsm"
                            [ngClass]="
                                task.mediaType === 'Voice'
                                    ? 'btn-voice-accept'
                                    : 'btn-other-accept'
                            "
                            (click)="onAcceptClick()"
                        >
                            Accept
                        </button>
                    </div>
                    <div class="me-2 mt-1">
                        <button
                            class="btn btn-xsm"
                            [ngClass]="
                                task.mediaType === 'Voice'
                                    ? 'btn-voice-reject'
                                    : 'btn-other-reject'
                            "
                            (click)="onRejectClick()"
                        >
                            Reject
                        </button>
                    </div>
                </div>
            }
        </div>
    </div>
</div>
@if (timeout > 0 && task.workItemState === 'Preview' && displayProgress) {
    <telxl-shared-task-progress
        [totalSeconds]="timeout"
        [createdAtActual]="task.createdAtActual"
        [stop]="stopProgress"
        [isTask]="true"
    />
}
