import { Injectable, OnDestroy, signal } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, Subscription } from 'rxjs';
import {
    IEmail,
    IInboundEmailEvent,
    IEmailConversationInfoEvent,
    IOutboundEmail,
} from '@dxp/shared/models';
import { EmailHubService } from '@dxp/shared/signalr';
import { ContentService } from '@dxp/shared/api';
import { LoggerService } from '../logger/logger.service';

@Injectable({
    providedIn: 'root',
})
export class EmailService implements OnDestroy {
    private emailsSubject = new BehaviorSubject<IEmail[]>([]);
    emails$ = this.emailsSubject.asObservable();

    private conversationInfoSubject =
        new BehaviorSubject<IEmailConversationInfoEvent | null>(null);
    conversationInfo$ = this.conversationInfoSubject.asObservable();
    public _inboundEmailEvent = signal<IInboundEmailEvent>(
        {} as IInboundEmailEvent,
    );

    private replyContentSubject = new BehaviorSubject<string>('');
    replyContent$ = this.replyContentSubject.asObservable();

    private emailMessageSubscription: Subscription | null = null;

    constructor(
        private logger: LoggerService,
        private emailHubService: EmailHubService,
        private contentService: ContentService,
    ) {}

    initializeConversationAndFetchEmails(
        conversationId: string,
    ): Observable<void> {
        const initialize$ = new Observable<void>(observer => {
            this.emailMessageSubscription =
                this.emailHubService.emailMessage$.subscribe(
                    (inboundEmailEvent: IInboundEmailEvent) =>
                        this.handleInboundEmailEvent.bind(inboundEmailEvent),
                );

            observer.next();
            observer.complete();
        });

        const fetchEmails$ = this.contentService.getEmails(conversationId);

        return new Observable<void>(observer => {
            forkJoin([initialize$, fetchEmails$]).subscribe({
                next: ([_, emails]: [void, IEmail[]]) => {
                    const filteredEmail = emails.filter(
                        email => email.conversationId === conversationId,
                    );
                    this.emailsSubject.next(filteredEmail);
                    observer.next();
                    observer.complete();
                },
                error: err => {
                    this.logger.error(
                        'Error initializing and fetching emails:',
                        err,
                    );
                    observer.error(err);
                },
            });
        });
    }

    private handleInboundEmailEvent(event: IInboundEmailEvent): void {
        const newEmail = this.convertInboundEventToEmail(event);
        this.addEmail(newEmail);
    }

    private addEmail(newEmail: IEmail): void {
        const currentEmails = this.emailsSubject.getValue();
        const updateEmails = [...currentEmails, newEmail];

        this.logger.debug('Converted email:', newEmail);

        this.emailsSubject.next(updateEmails);
    }

    private convertInboundEventToEmail(event: IInboundEmailEvent): IEmail {
        return {
            messageId: event.id,
            conversationId: event.conversationId,
            threadId: event.threadId || '',
            creationDate: event.creationDate,
            subject: event.subject || 'No Subject',
            from: event.from,
            participantId: undefined,
            bodyId: event.bodyId || '',
            bodyPreview: event.bodyPreview || '',
            body: undefined,
            replyTo: event.replyTo || [],
            toRecipients: event.toRecipients || [],
            ccRecipients: event.ccRecipients || [],
            bccRecipients: event.bccRecipients || [],
            attachments: event.attachments || [],
            lastSeenByUserId: undefined,
            senderType: null,
        };
    }

    replyToEmail(conversationId: string, email: IEmail, content: string): void {
        const fromName = email.from?.name || 'Unknown Sender';
        const fromAddress = email.from?.address || 'unknown@example.com';

        const combinedContent = this.formatReplyContent(
            content,
            email.body?.content || email.bodyPreview || '',
            fromName,
            email.creationDate,
        );

        const replyEmail: IOutboundEmail = {
            sendType: 'Reply',
            subject: `Re:${email.subject}`,
            content: combinedContent,
            toRecipients: [{ name: fromName, address: fromAddress }],
            ccRecipients: [],
            bccRecipients: [],
            attachments: [],
            existingAttachments: [],
            newAttachments: [],
            contentImageUrls: [],
            threadId: '',
            bodyId: email.bodyId,
            bodyPreview: email.bodyPreview || '',
        };

        try {
            this.emailHubService.sendEmail(conversationId, 'Reply', replyEmail);
            this.logger.debug('Reply sent successfully.');
        } catch (error) {
            this.logger.error('Failed to send reply:', error);
        }
    }

    private formatReplyContent(
        replyContent: string,
        originalContent: string,
        originalSenderName: string,
        originalSentDate: string,
    ): string {
        const sanitizedContent = this.sanitizeHtmlContent(originalContent);
        const formattedDate = new Date(originalSentDate).toLocaleString(
            'en-GB',
            {
                dateStyle: 'medium',
                timeStyle: 'short',
            },
        );
        return `
        <!DOCTYPE html>
        <html>
        <head>
            <meta charset="UTF-8">
            <style>
                body { font-family: Arial, sans-serif; line-height: 1.6; }
                p { margin: 0 0 10px; }
                blockquote { margin: 10px 0; padding: 10px; border-left: 2px solid #ccc; background-color: #f9f9f9; }
            </style>
        </head>
        <body>
            <p>${replyContent}</p>
            <blockquote>
                <p>On ${formattedDate}, ${originalSenderName} wrote:</p>
                ${sanitizedContent}
            </blockquote>
        </body>
        </html>
    `;
    }

    private sanitizeHtmlContent(html: string): string {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');

        let bodyContent = doc.body ? doc.body.innerHTML : html;

        bodyContent = bodyContent
            .replace(/<style[\s\S]*?>[\s\S]*?<\/style>/gi, '')
            .replace(/<meta[\s\S]*?>/gi, '')
            .replace(/<!--[\s\S]*?-->/g, '');

        bodyContent = bodyContent.replace(
            /class="MsoNormal"/gi,
            'style="margin: 0 0 10px; font-family: Arial, sans-serif;"',
        );

        return bodyContent.trim();
    }

    setReplyContent(content: string): void {
        this.replyContentSubject.next(content);
    }

    getReplyContent(): string {
        return this.replyContentSubject.getValue();
    }

    ngOnDestroy(): void {
        if (this.emailMessageSubscription) {
            this.emailMessageSubscription.unsubscribe();
        }
    }
}
