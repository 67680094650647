import {
    ChangeDetectorRef,
    Component,
    NgZone,
    OnDestroy,
    OnInit,
    Signal,
    computed,
} from '@angular/core';
import {
    IAgent,
    IQueue,
    IQueueStatistics,
    ITeam,
    IUser,
    IUserPresence,
    queueStatisticDefault,
} from '@dxp/shared/models';
import {
    AgentService,
    QueueService,
    StateService,
    TeamService,
    UserService,
} from '@dxp/shared/services';
import { Duration, intervalToDuration } from 'date-fns';
import { Subscription, interval } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
    DoughnutItems,
    TelXLButtonComponent,
    TelXLDoughnutComponent,
} from '@dxp/elements';
import { secondsToDuration } from '@dxp/shared/helpers';
import { FormatDurationPipe } from '@dxp/shared/pipes';

@Component({
    selector: 'app-shell-main-layout-break',
    standalone: true,
    imports: [
        CommonModule,
        FormatDurationPipe,
        TelXLButtonComponent,
        TelXLDoughnutComponent,
    ],
    templateUrl: './main-layout-break.component.html',
    styleUrl: './main-layout-break.component.scss',
})
export class MainLayoutBreakComponent implements OnInit, OnDestroy {
    presence: Signal<IUserPresence>;
    intervalSubscription: Subscription | undefined;
    teams: Signal<ITeam[]>;
    now = new Date();
    queues: Signal<IQueue[]>;
    timedBreakExpiresAt: Date | undefined;

    constructor(
        private userService: UserService,
        private ngZone: NgZone,
        private cdr: ChangeDetectorRef,
        private agentService: AgentService,
        private teamService: TeamService,
        private queueService: QueueService,
        private stateService: StateService,
    ) {
        this.teams = this.teamService.teams;
        this.queues = this.queueService.queues;
        this.queues = this.queueService.queues;
        this.presence = this.userService.presence;
    }

    DoughnutItems = DoughnutItems;
    secondsToDuration = secondsToDuration;

    teamId = '';
    queueId = '';
    selectedTeam = '';
    selectedQueue = '';

    team = computed<ITeam | undefined>(() =>
        this.teams().find(t => t.id === this.teamId),
    );

    agent = computed<IAgent | undefined>(() =>
        this.agentService
            .agents()
            .find(a => a.hasActivity && a.id === this.userService.user().id),
    );

    queue = computed<IQueue | undefined>(() =>
        this.queues().find(q => q.id === this.queueId),
    );

    ngOnInit(): void {
        if (this.presence) {
            this.timedBreakExpiresAt = this.presence().timedBreakExpiresAt;
        }
        this.ngZone.runOutsideAngular(() => {
            this.intervalSubscription = interval(1000).subscribe(() => {
                this.now = new Date();
                this.ngZone.run(() => {
                    this.cdr.detectChanges();
                });
            });
        });
        const initialTeams = this.teams();
        if (initialTeams.length > 0) {
            this.selectedTeam = initialTeams[0].name;
            this.teamId = initialTeams[0].id;
        }
        const initialQueues = this.queues();
        if (initialQueues.length > 0) {
            this.selectedQueue = initialQueues[0].name;
            this.queueId = initialQueues[0].id;
        }
    }

    onTeamChange(team: ITeam): void {
        this.selectedTeam = team.name;
        this.teamId = team.id;
    }

    onQueueChange(queue: IQueue): void {
        this.selectedQueue = queue.name;
        this.queueId = queue.id;
    }

    get currentQueueStatistics(): IQueueStatistics {
        return this.queue()?.statistics() ?? queueStatisticDefault();
    }

    ngOnDestroy(): void {
        if (this.intervalSubscription) {
            this.intervalSubscription.unsubscribe();
        }
    }

    get stateSince(): Duration {
        if (this.timedBreakExpiresAt) {
            return intervalToDuration({
                start: new Date(),
                end: new Date(this.timedBreakExpiresAt),
            });
        }

        return intervalToDuration({
            start: new Date(
                this.presence ? this.presence().stateSince : new Date(),
            ),
            end: this.now,
        });
    }

    endBreak(): void {
        this.stateService.onActivate();
    }

    get user(): IUser {
        return this.userService.user();
    }
}
