import { Component, Input, OnInit, Signal } from '@angular/core';
import { ICallState, IIncomingTask, IWorkItem } from '@dxp/shared/models';
import { SipService } from '@dxp/shared/services';
import { ChatHubService } from '@dxp/shared/signalr';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { TelXLTaskProgressComponent } from '@dxp/components';
import { VoiceApiService } from '@dxp/shared/api';
import { map } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
import { AgentService } from '@dxp/shared/services';

@Component({
    selector: 'app-shell-task-belt-item',
    standalone: true,
    imports: [CommonModule, TelXLTaskProgressComponent],
    templateUrl: './task-belt-item.component.html',
    styleUrl: './task-belt-item.component.scss',
})
export class TaskBeltItemComponent implements OnInit {
    @Input() createdAtActual = new Date();
    @Input() isSelected = false;
    @Input() timeout = 0;
    @Input() task!: IIncomingTask;

    workItem: Signal<IWorkItem> | undefined;
    stopProgress = false;
    displayTaskButtons = false;
    displayProgress = true;

    constructor(
        private sipService: SipService,
        private router: Router,

        private voiceApiService: VoiceApiService,
        private chatHubService: ChatHubService,
        private agentService: AgentService,
    ) {
        const workItem$ = this.agentService.agentWorkItems$.pipe(
            map(
                wi =>
                    wi.find(
                        w => w.workItemId === this.task.workItemId,
                    ) as IWorkItem,
            ),
        );
        this.workItem = toSignal(workItem$, { initialValue: {} as IWorkItem });
    }

    ngOnInit() {
        this.displayTaskButtons =
            this.task.workItemState === 'Preview' &&
            this.timeout > 0 &&
            this.task.mediaType !== 'Voice';
        this.displayProgress = this.task.mediaType !== 'Voice';
    }

    onClick() {
        if (!this.displayTaskButtons) {
            this.stopProgress = true;
            this.isSelected = true;
            if (this.workItem) {
                this.router.navigate([
                    this.task.navigation,
                    this.task.workItemId,
                ]);
            }
        }
    }

    onAcceptClick(): void {
        if (this.task.mediaType === 'Voice') {
            this.sipService.answerCall();
            this.task.acceptedAt = new Date();
            const params: ICallState = {
                conversationId: this.task.conversationId,
                callState: 'Answer',
            };
            this.voiceApiService.changeCallState(params);
        } else {
            this.chatHubService.accept({
                conversationId: this.task.conversationId,
            });
        }

        this.isSelected = true;
        this.displayTaskButtons = false;
        this.router.navigate([this.task.navigation, this.task.workItemId]);
        this.timeout = 0;
    }

    onRejectClick(): void {
        if (this.task.mediaType === 'Voice') {
            const params: ICallState = {
                conversationId: this.task.conversationId,
                callState: 'Hangup',
            };
            this.voiceApiService.changeCallState(params);
            this.sipService.reject();
        } else {
            this.chatHubService.reject({
                conversationId: this.task.conversationId,
            });
        }
        this.displayProgress = false;
        this.displayTaskButtons = false;
    }
}
