import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { IOutboundEmail } from '../../models/conversation/email/email';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import {
    IConversationEvent,
    IInboundEmailEvent,
} from '../../models/conversation/email/events';

import { LoggerService } from '../../services/logger/logger.service';
import { SignalRHubService } from '../signalr.hub.service';

@Injectable({
    providedIn: 'root',
})
export class EmailHubService extends SignalRHubService {
    private emailMessageSubject = new Subject<IInboundEmailEvent>();
    private refreshSubject = new Subject<IConversationEvent>();

    emailMessage$ = this.emailMessageSubject.asObservable();
    refresh$ = this.refreshSubject.asObservable();

    constructor(
        logger: LoggerService,
        oidcSecurityService: OidcSecurityService,
    ) {
        super(
            'Email',
            `${environment.apiBaseUrl}/email/hub`,
            oidcSecurityService,
            logger,
        );
    }

    onReceiveEmailMessage(event: IInboundEmailEvent) {
        this.emailMessageSubject.next(event);
    }

    acceptEmail(conversationId: string) {
        this.hubConnection?.send('Accept', { conversationId });
    }

    peekOrConnect(conversationId: string) {
        this.hubConnection?.send('PeekOrConnect', { conversationId });
    }

    rejectEmail(conversationId: string) {
        this.hubConnection?.send('Reject', { conversationId });
    }

    sendEmail(conversationId: string, sendType: string, email: IOutboundEmail) {
        this.send(sendType, { ...email, conversationId });
    }

    protected override registerHandlers() {
        this.hubConnection.on(
            'ReceiveEmailMessage',
            (emailMessage: IInboundEmailEvent) => {
                this.messageReceived();

                this.logger.debug('Email Service (ReceiveEmailMessage) ->');
                this.logger.table(emailMessage);

                this.emailMessageSubject.next(emailMessage);
            },
        );

        this.hubConnection.on(
            'RequestRefresh',
            (requestRefresh: IConversationEvent) => {
                this.messageReceived();

                this.logger.debug('Chat Service (RequestRefresh) ->');
                this.logger.table(requestRefresh);

                this.refreshSubject.next(requestRefresh);
            },
        );
    }
    protected override unregisterHandlers() {
        this.hubConnection.off('ReceiveEmailMessage');
        this.hubConnection.off('RequestRefresh');
    }
}
