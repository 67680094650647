import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { QueueMediaType } from '@dxp/shared/models';

@Component({
    selector: 'telxl-media-tag',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './media-tag.component.html',
    styleUrls: ['./media-tag.component.scss'],
    host: {
        '[class]': 'class || ""',
    },
})
export class TelXLMediaTagComponent {
    @Input({ required: true }) media!: QueueMediaType;

    get mediaTypeIcon(): string {
        switch (this.media) {
            case 'Voice':
                return 'fa-phone text-success';
            case 'Email':
                return 'fa-envelope text-email';
            case 'Webchat':
                return 'fa-message text-webchat';
            case 'Socials':
                return 'fa-share-nodes text-socials';
            default:
                return 'fa-square-check text-primary';
        }
    }
}
