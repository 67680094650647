<div
    class="h-100 d-flex flex-column align-items-start side-nav"
    id="side-nav-container"
>
    <div class="nav-branding mt-3 mb-2 align-items-center">
        <a href="#">
            <img
                class="brand-image"
                src="assets/images/cxp-signet.svg"
                alt="Cxp Logo"
                id="header-brand-image"
            />
        </a>
    </div>
    <div class="w-100 task-row" id="side-nav-live-tasks">
        <span class="my-live-tasks w-100">Live Tasks ></span>
    </div>

    <ul
        class="nav nav-pills flex-column me-auto mb-lg-0 w-100 pt-2 h-100"
        id="side-nav-bar-container"
        [ngClass]="{ 'disabled-section': presence().state === 'Break' }"
    >
        @for (link of links; track link.label) {
            @if (canRender(link.permission, link.environments)) {
                <app-shell-side-nav-item
                    [iconName]="link.icon"
                    [label]="link.label"
                    [routerLink]="link.routeLink"
                    class="pb-1 pt-2 w-100 nav-item-pill"
                    id="side-nav-bar-pill-{{
                        link.label | lowercase | replaceSpaces
                    }}"
                />
            }
        }

        @if (allowResponsive) {
            <li id="side-nav-item-responsive" class="circle-label mt-2">
                <span
                    class="font-sm text-white"
                    id="side-nav-item-button-text-responsive"
                >
                    <span
                        class="d-none d-sm-none d-md-none d-lg-none d-xl-none d-xxl-none d-xs-block"
                    >
                        XS
                    </span>
                    <span
                        class="d-none d-xs-none d-md-none d-lg-none d-xl-none d-xxl-none d-sm-block"
                    >
                        SM
                    </span>
                    <span
                        class="d-none d-xs-none d-sm-none d-lg-none d-xl-none d-xxl-none d-md-block"
                    >
                        MD
                    </span>
                    <span
                        class="d-none d-xs-none d-sm-none d-md-none d-xl-none d-xxl-none d-lg-block"
                    >
                        LG
                    </span>
                    <span
                        class="d-none d-xs-none d-sm-none d-md-none d-lg-none d-xxl-none d-xl-block"
                    >
                        XL
                    </span>
                    <span
                        class="d-none d-xs-none d-sm-none d-md-none d-lg-none d-xl-none d-xxl-block"
                    >
                        XXL
                    </span>
                </span>
            </li>
        }

        @if (allowLogout) {
            <app-shell-side-nav-item
                iconName="fa-sign-out"
                label="Logout"
                class="pb-1 pt-2 w-100 nav-item-pill mt-auto"
                (click)="logout()"
                id="side-nav-bar-pill-logout"
            />
        }
    </ul>
</div>
