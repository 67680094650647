import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { IEmail, IEmailBody } from '../../models';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ContentService {
    private baseUrl = environment.contentUrl;

    constructor(private http: HttpClient) {}

    getEmailDetails(messageId: string): Observable<IEmail> {
        return this.http.get<IEmail>(
            `${this.baseUrl}/messages/email/${messageId}`,
        );
    }

    getEmails(conversationId: string): Observable<IEmail[]> {
        return this.http.get<IEmail[]>(
            `${this.baseUrl}/messages/email/byconversationid?conversationId=${conversationId}`,
        );
    }

    getMessagesInConversation(conversationId: string): Observable<IEmail[]> {
        return this.getEmails(conversationId);
    }

    getMessagesInThread(threadId: string, token: string): Observable<IEmail[]> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });
        return this.http.get<IEmail[]>(
            `${this.baseUrl}/messages/email/thread/${threadId}`,
            {
                headers,
            },
        );
    }

    getEmailBody(bodyId: string): Observable<IEmailBody> {
        return this.http.get<IEmailBody>(
            `${this.baseUrl}/messages/email/${bodyId}`,
        );
    }

    uploadEmailBody(body: IEmailBody): Observable<IEmailBody> {
        return this.http.post<IEmailBody>(
            `${this.baseUrl}/messages/email`,
            body,
        );
    }

    markAsLastSeenByMe(email: IEmail, userId: string): Observable<void> {
        if (email.lastSeenByUserId === userId) {
            return new Observable(observer => {
                observer.next();
                observer.complete();
            });
        }

        return this.http.post<void>(
            `${this.baseUrl}/messages/email/${email.messageId}/seenbyuser/${userId}`,
            {},
        );
    }

    deleteFile(url: string): Observable<void> {
        return this.http.delete<void>(url);
    }

    invalidateEmailCache(): Observable<void> {
        return of(undefined);
    }
}
