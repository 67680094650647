import { AgentState } from '../presence/user-presence';

export type ChannelType =
    | ''
    | 'webChat'
    | 'voice'
    | 'twitter'
    | 'instagram'
    | 'virtual'
    | 'whatsApp'
    | 'facebook'
    | 'sms';

export type WorkItemState =
    | 'LookUp'
    | 'Preview'
    | 'Connected'
    | 'Afterwork'
    | 'Closed';

export const MediaTypes = ['Webchat', 'Voice', 'Email', 'Messaging'] as const;
export type MediaType = (typeof MediaTypes)[number];

export type ManualAssignResult = 'Ok' | 'NotFound' | 'NotStaffed';

export enum TaskOrder {
    Voice = 1,
    Webchat = 2,
    Messaging = 3,
    Email = 4,
    Unknown = 5,
}

export interface IAssignToMe {
    result: ManualAssignResult;
}

export interface ICompleteWorkItemParams {
    workItemId: string;
    closureCode: string;
    notes: string;
}

export interface IAgentProperties {
    id: string;
    state: AgentState;
    stateSince: Date;
    breakName: string;
    capabilities: string[];
    displaySettings: {
        hideSensitiveAgentInformation: boolean;
        hideExternalContacts: boolean;
    };
    timedBreakExpiresAt: Date;
}

export interface IWorkItems {
    [workItemId: string]: {
        workItemId: string;
        conversations: IConversations;
        businessUnitId: string | null;
        customerId: string | null;
        workItemState: WorkItemState;
        lastStateChangeDate: Date;
        createdAt: Date;
        primaryConversationId: string;
        usableClosureCodes: string[];
        channelId: number;
        primaryMediaType: MediaType;
        primaryChannelType: ChannelType;
        remainingWrapUpResets: number;
        wrapupDurationSeconds: number;
    };
}

export interface IWorkItem {
    workItemId: string;
    conversations: IConversation[];
    businessUnitId: string | null;
    customerId: string | null;
    workItemState: WorkItemState;
    lastStateChangeDate: Date;
    createdAt: Date;
    primaryConversationId: string;
    usableClosureCodes: string[];
    channelId: number;
    primaryMediaType: MediaType;
    primaryChannelType: ChannelType;
    remainingWrapUpResets: number;
    wrapupDurationSeconds: number;
    forceCompletionCodeSelection: boolean;
    queueName: string;
    acceptedAt?: Date;
}

export interface IConversations {
    [conversationId: string]: IConversation;
}

export interface IConversation {
    conversationId: string;
    channelType: ChannelType;
    queueName: string;
    queuedAt: Date;
    dequeuedAt: Date | null;
    workflowProperties: object;
    fromNumber: string;
    dialedNumber: string;
    callerIdName: string;
    position: number;
}

export interface IAgentClientState {
    agentProperties: IAgentProperties;
    workItems: IWorkItems;
    timeStamp: Date;
}

export interface IAgentStateTransitionRequested {
    toState: string;
    initiatedBy: string;
}

export interface IWorkItemKpis {
    handledCount: number;
    missedCount: number;
    averageHandlingTimeInSeconds: number;
}

export interface IHandledCountKpi {
    handledCount: number;
}
