import {
    IWorkItemKpis,
    MediaType,
    MediaTypes,
} from '../models/agent/agent-state';
import { IValueWithMetric } from '../models/metrics/metric';
import { ITeamThreshold } from '../models/rbac/team-kpi';
import { toPercentage } from './metric';

export type KeyValuePair<T> = { key: string; value: T };

export const reduceToObject = <TValue, TRes>(
    arr: KeyValuePair<TValue>[],
    selector: (value: TValue) => unknown = v => v,
): Record<string, TRes> =>
    arr.reduce(
        (coll, { key, value }) => ({ ...coll, [key]: selector(value) }),
        {},
    );

export const recordToArray = (
    record: Record<string, { value: number; percentage: number }>,
): {
    name: string;
    value: number;
    percentage: number;
}[] => {
    return Object.keys(record).map(key => ({
        name: key,
        value: record[key].value,
        percentage: record[key].percentage,
    }));
};

export const calculateWithPercentage = <T>(
    keys: readonly string[],
    data: KeyValuePair<T>[],
    getValue: (item: T) => number,
): Record<string, { value: number; percentage: number }> => {
    const result = reduceToObject<T, T>(data);

    const stateObj = keys.reduce(
        (acc, key) => {
            acc[key] = { value: 0, percentage: 0 };
            return acc;
        },
        {} as Record<string, { value: number; percentage: number }>,
    );

    if (data.length === 0) {
        return stateObj;
    }

    const totalValue = keys.reduce((total, key) => {
        const value = getValue(result[key]) ?? 0;
        stateObj[key].value = value;
        return total + value;
    }, 0);

    if (totalValue > 0) {
        let totalRoundedPercentage = 0;
        const unroundedPercentages: Record<string, number> = {};

        keys.forEach(key => {
            const unroundedPercentage =
                (stateObj[key].value / totalValue) * 100;
            unroundedPercentages[key] = unroundedPercentage;
            const roundedPercentage = Math.round(unroundedPercentage);
            stateObj[key].percentage = roundedPercentage;
            totalRoundedPercentage += roundedPercentage;
        });

        const difference = 100 - totalRoundedPercentage;

        if (difference !== 0) {
            const adjustmentKey = keys.reduce((acc, key) => {
                const error =
                    unroundedPercentages[key] - stateObj[key].percentage;
                return Math.abs(error) >
                    Math.abs(
                        unroundedPercentages[acc] - stateObj[acc].percentage,
                    )
                    ? key
                    : acc;
            }, keys[0]);

            stateObj[adjustmentKey].percentage += difference;
        }
    }

    return stateObj;
};

export const convertToMediaTypeRecord = (
    data: KeyValuePair<IWorkItemKpis>[],
    kpi?: {
        voice: ITeamThreshold;
        email: ITeamThreshold;
        webchat: ITeamThreshold;
        socials: ITeamThreshold;
        average: ITeamThreshold;
    },
): Record<
    MediaType,
    {
        split: number;
        handledCount: number;
        missedCount: number;
        averageHandlingTimeInSeconds: IValueWithMetric;
        ratio: number;
    }
> => {
    const acc = MediaTypes.reduce(
        (result, mediaType) => {
            result[mediaType] = {
                handledCount: 0,
                missedCount: 0,
                averageHandlingTimeInSeconds: {
                    value: 0,
                    metric: { percent: 0, states: [] },
                },
                ratio: 0,
                split: 0,
            };
            return result;
        },
        {} as Record<
            MediaType,
            {
                handledCount: number;
                missedCount: number;
                averageHandlingTimeInSeconds: IValueWithMetric;
                ratio: number;
                split: number;
            }
        >,
    );

    let totalMediaInteractions = 0;

    data.forEach(({ key, value }) => {
        if (MediaTypes.includes(key as MediaType)) {
            const mediaKpi = kpi
                ? mediaKpiByMediaType(kpi, key as MediaType)
                : undefined;

            acc[key as MediaType].handledCount = value.handledCount;
            acc[key as MediaType].missedCount = value.missedCount;
            acc[key as MediaType].averageHandlingTimeInSeconds = {
                value: value.averageHandlingTimeInSeconds,
                metric:
                    mediaKpi && mediaKpi.enabled
                        ? {
                              percent: toPercentage(
                                  value.averageHandlingTimeInSeconds /
                                      mediaKpi.target,
                              ),
                              states: [
                                  {
                                      value: toPercentage(
                                          mediaKpi.low / mediaKpi.target,
                                      ),
                                      state: 'warning',
                                  },
                                  {
                                      value: toPercentage(
                                          mediaKpi.high / mediaKpi.target,
                                      ),
                                      state: 'danger',
                                  },
                              ],
                          }
                        : { percent: 0, states: [] },
            };

            totalMediaInteractions += value.handledCount + value.missedCount;
        }
    });

    let totalSplit = 0;
    data.forEach(({ key }) => {
        if (MediaTypes.includes(key as MediaType)) {
            const mediaTypeKey = key as MediaType;
            const totalInteractions =
                acc[mediaTypeKey].handledCount + acc[mediaTypeKey].missedCount;
            const ratio =
                totalInteractions > 0
                    ? (acc[mediaTypeKey].handledCount / totalInteractions) * 100
                    : 0;

            const split =
                totalMediaInteractions > 0
                    ? (totalInteractions / totalMediaInteractions) * 100
                    : 0;

            acc[mediaTypeKey].ratio = Math.round(ratio);
            acc[mediaTypeKey].split = Math.round(split);
            totalSplit += acc[mediaTypeKey].split;
        }
    });

    const splitDifference = 100 - totalSplit;
    if (splitDifference > 0 && splitDifference < 100) {
        const mediaTypeWithLargestTotalInteractions = (
            Object.keys(acc) as MediaType[]
        ).reduce(
            (largestMediaType, currentMediaType) =>
                acc[currentMediaType].handledCount +
                    acc[currentMediaType].missedCount >
                acc[largestMediaType].handledCount +
                    acc[largestMediaType].missedCount
                    ? currentMediaType
                    : largestMediaType,
            MediaTypes[0],
        );

        acc[mediaTypeWithLargestTotalInteractions].split += splitDifference;
    }

    return acc;
};

export const aggregateWorkItemsPerMediaType = (
    data: KeyValuePair<IWorkItemKpis>[],
    kpi?: ITeamThreshold,
): {
    handled: number;
    missed: number;
    ratio: number;
    averageHandlingTimeInSeconds: IValueWithMetric;
} => {
    const totals = data.reduce(
        (acc, { value }) => {
            acc.handled += value.handledCount;
            acc.missed += value.missedCount;
            acc.averageHandlingTimeInSeconds +=
                value.averageHandlingTimeInSeconds;
            return acc;
        },
        { handled: 0, missed: 0, averageHandlingTimeInSeconds: 0 },
    );

    const totalInteractions = totals.handled + totals.missed;
    const ratio =
        totalInteractions > 0
            ? Math.round((totals.handled / totalInteractions) * 100)
            : 0;

    const itemsWithValue = data.filter(
        d => d.value.averageHandlingTimeInSeconds > 0,
    ).length;

    const averageHandlingTimeInSeconds =
        itemsWithValue > 0
            ? Math.round(totals.averageHandlingTimeInSeconds / itemsWithValue)
            : 0;

    return {
        handled: totals.handled,
        missed: totals.missed,
        ratio: ratio,
        averageHandlingTimeInSeconds: {
            value: averageHandlingTimeInSeconds,
            metric:
                kpi && kpi.enabled
                    ? {
                          percent: toPercentage(
                              averageHandlingTimeInSeconds / kpi.target,
                          ),
                          states: [
                              {
                                  value: toPercentage(kpi.low / kpi.target),
                                  state: 'warning',
                              },
                              {
                                  value: toPercentage(kpi.high / kpi.target),
                                  state: 'danger',
                              },
                          ],
                      }
                    : { percent: 0, states: [] },
        },
    };
};

const mediaKpiByMediaType = (
    kpi: {
        voice: ITeamThreshold;
        email: ITeamThreshold;
        webchat: ITeamThreshold;
        socials: ITeamThreshold;
        average: ITeamThreshold;
    },
    mediaType: MediaType,
): ITeamThreshold => {
    switch (mediaType) {
        case 'Voice':
            return kpi.voice;
        case 'Email':
            return kpi.email;
        case 'Webchat':
            return kpi.webchat;
        case 'Messaging':
            return kpi.socials;
        default:
            return kpi.average;
    }
};
