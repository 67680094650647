export interface IDoughnutItem {
    value: string;
    class: string;
    showPercentage: boolean;
}

export const DoughnutItems = (items: IDoughnutItem[]): string => {
    return items.map(item => DoughnutItem(item)).join('<br />');
};

export const DoughnutItem = (item: IDoughnutItem): string => {
    let content = `<span class="${item.class}">${item.value}`;

    if (item.showPercentage) {
        content += `<small>%</small>`;
    }

    return content + '</span>';
};
