import { Component, Signal } from '@angular/core';
import {
    EnvironmentType,
    INavLink,
    IUserPresence,
    navbarLinks,
} from '@dxp/shared/models';

import { AuthService } from '@dxp/shared/api';
import { CommonModule } from '@angular/common';
import { ReplaceSpacesPipe } from '@dxp/shared/pipes';
import { RouterModule } from '@angular/router';
import { SideNavItemComponent } from './../item/side-nav-item.component';
import { UserService } from '@dxp/shared/services';
import { environment } from '@dxp/shared/environment';

@Component({
    selector: 'app-shell-side-nav',
    standalone: true,
    templateUrl: './side-nav.component.html',
    styleUrl: './side-nav.component.scss',
    imports: [
        CommonModule,
        RouterModule,
        ReplaceSpacesPipe,
        SideNavItemComponent,
    ],
})
export class SideNavComponent {
    presence: Signal<IUserPresence>;

    constructor(
        private userService: UserService,
        private authService: AuthService,
    ) {
        this.presence = this.userService.presence;
    }

    get links(): INavLink[] {
        return navbarLinks;
    }

    get allowResponsive(): boolean {
        return (
            environment.environment === EnvironmentType.Local ||
            environment.environment === EnvironmentType.Dev
        );
    }

    get allowLogout(): boolean {
        return environment.environment !== EnvironmentType.Production;
    }

    canRender(permission?: string, environments?: EnvironmentType[]): boolean {
        if (
            environments &&
            environments.length > 0 &&
            !environments.includes(environment.environment)
        ) {
            return false;
        }

        return permission ? this.userService.hasPermission(permission) : true;
    }

    logout() {
        this.authService.logout();
    }
}
