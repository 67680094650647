import { CommonModule } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'telxl-spinner-input',
    standalone: true,
    imports: [CommonModule, FormsModule],
    templateUrl: './spinner-input.component.html',
    styleUrl: './spinner-input.component.scss',
})
export class TelXLSpinnerInputComponent implements OnChanges {
    @Input() title!: string;
    @Input({ required: true }) isEnabled = false;
    @Input({ required: true }) minValue = 0;
    @Input({ required: true }) maxValue = 100;
    @Input() value!: number | null;
    @Input() placeholder = '';
    @Input() twoDigit = false;
    @Input() error = false;
    @Output() valueChange: EventEmitter<number | null> = new EventEmitter<
        number | null
    >();

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes['isEnabled'] &&
            changes['isEnabled'].currentValue !==
                changes['isEnabled'].previousValue
        ) {
            this.isEnabled = changes['isEnabled'].currentValue;
            this.value = this.isEnabled ? 0 : null;

            if (!changes['isEnabled'].isFirstChange()) {
                this.emitValueChange();
            }
        }

        if (
            changes['value'] &&
            changes['value'].currentValue !== changes['value'].previousValue
        ) {
            this.value = this.isEnabled ? changes['value'].currentValue : null;

            if (!changes['value'].isFirstChange()) {
                this.emitValueChange();
            }
        }
    }

    increaseValue() {
        if (this.value == undefined) this.value = this.minValue;

        if (this.value < this.maxValue) {
            this.value++;
        }

        this.emitValueChange();
    }

    decreaseValue() {
        if (this.value == undefined) this.value = this.minValue;

        if (this.value > this.minValue) {
            this.value--;
        }

        this.emitValueChange();
    }

    onInputChange(value: string | null | undefined) {
        if (value === null || value === undefined) {
            this.emitValueChange();
            return;
        }

        const numberValue = Number(value);

        if (isNaN(numberValue) || value.toString().trim() === '') {
            this.value = this.minValue;
        } else if (numberValue < this.minValue) {
            this.value = this.minValue;
        } else if (numberValue > this.maxValue) {
            this.value = this.maxValue;
        } else {
            this.value = numberValue;
        }

        this.emitValueChange();
    }

    formatValue(value: number | null): string {
        return value !== null && value !== undefined
            ? this.twoDigit && value < 10
                ? '0' + value
                : value.toString()
            : this.minValue.toString();
    }

    validateInput(event: Event) {
        const input = event.target as HTMLInputElement;
        const value = Number(input.value);

        if (isNaN(value) || value < this.minValue || value > this.maxValue) {
            input.value = this.formatValue(this.value);
        }
    }

    restrictKeys(event: KeyboardEvent) {
        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        if (!allowedKeys.includes(event.key)) {
            event.preventDefault();
        }
    }

    private emitValueChange() {
        this.valueChange.emit(this.value);
    }
}
