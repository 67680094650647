<div
    class="form-label font-sm weight-medium pb-0 mb-0"
    [ngClass]="{ error: error }"
>
    {{ title }}&nbsp;
</div>
<div class="input-group">
    <input
        type="number"
        class="form-control font-sm pt-0 pb-0 ps-2 pe-1"
        [ngClass]="{ error: error }"
        [ngModel]="formatValue(value)"
        (ngModelChange)="onInputChange($event)"
        (blur)="onInputChange(value?.toString())"
        (input)="validateInput($event)"
        (keypress)="restrictKeys($event)"
        [disabled]="!isEnabled"
        [placeholder]="placeholder"
    />
    <span
        class="input-group-selector d-flex flex-column"
        [ngClass]="{ error: error }"
    >
        <button
            class="btn btn-spinner"
            [ngClass]="{ error: error }"
            type="button"
            (click)="increaseValue()"
            [disabled]="!isEnabled"
        >
            <i class="fa-solid fa-caret-up fa-sm"></i>
        </button>
        <button
            class="btn btn-spinner"
            [ngClass]="{ error: error }"
            type="button"
            (click)="decreaseValue()"
            [disabled]="!isEnabled"
        >
            <i class="fa-solid fa-caret-down fa-sm"></i>
        </button>
    </span>
</div>
