<div ngbDropdown class="d-inline-block width h-100" [placement]="placement">
    <button
        type="button"
        [ngClass]="dropdownClass"
        [disabled]="disabled"
        id="dropdownButtonMenu"
        ngbDropdownToggle
    >
        <div>{{ label }}</div>
    </button>
    <div
        ngbDropdownMenu
        aria-labelledby="dropdownButtonMenu"
        [style.max-height]="maxHeight"
    >
        @for (item of menuItems; track item.value) {
            <button
                [tabindex]="$index"
                ngbDropdownItem
                [ngClass]="dropdownItemClass(item)"
                (click)="setSelected(item.value)"
                (keypress)="handleKeyPress($event, item.value)"
                [disabled]="item.disabled"
            >
                {{ item.label }}
            </button>
        }
    </div>
</div>
