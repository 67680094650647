<div style="position: relative; width: 100%" [style.height]="height">
    <canvas
        baseChart
        [data]="doughnutChartData"
        [options]="doughnutChartOptions"
        [type]="doughnutChartType"
    ></canvas>

    <div class="chart-overlay">
        <ng-container *ngIf="content">
            <div [innerHTML]="content"></div>
        </ng-container>
    </div>
</div>
