import { CommonModule } from '@angular/common';
import {
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { findStateForMetric, getColourValue } from '@dxp/shared/helpers';
import { IMetric, MetricPaletteType } from '@dxp/shared/models';
import { ChartConfiguration } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

/**
 * Component to display nested ring charts.
 * Each ring represents a percentage value with a colour code indicating the status.
 *
 * @selector rings
 * @standalone true
 * @component RingsComponent
 * @imports CommonModule, BaseChartDirective
 *
 * @Input metrics - Array of objects conforming to IMetric interface, each representing a ring in the chart.
 * @Input label - Text to display.
 * @Input overall - The summary value for the data.
 *
 * Usage:
 * <telxl-rings [metric]="data" [label]="label" [overall]="overall"></rings>
 *
 * Note:
 * A data item contains the percent value of the ring and state change values.
 * The state change value is an array of numbers to set the RAG status.
 *
 * e.g. { percent: 80, state: [{25, 'success'}, {75, 'danger'}]}
 * In this scenario a percent value over 25 will render success state and over 75 will render danger state.
 *
 * e.g. { percent: 30, state: [{50, 'warning'}]}
 * In this scenario a percent value over 50 will render warning state. No red state is configured.
 *
 */
@Component({
    selector: 'telxl-rings',
    standalone: true,
    imports: [CommonModule, BaseChartDirective],
    templateUrl: './rings.component.html',
    styleUrl: './rings.component.scss',
})
export class TelXLRingsComponent implements OnChanges {
    // @ts-ignore
    private danger = getColourValue('--red-primary');

    // @ts-ignore
    private warning = getColourValue('--yellow-primary');
    // @ts-ignore

    // @ts-ignore
    private success = getColourValue('--green-primary');

    // @ts-ignore
    private dark = getColourValue('--dark-primary');

    // @ts-ignore
    private socials = getColourValue('--purple-primary');

    // @ts-ignore
    private webchat = getColourValue('--webchat-primary');

    // @ts-ignore
    private email = getColourValue('--email-primary');

    // @ts-ignore
    private primary = getColourValue('--blue-primary');
    private background = getColourValue('--background');

    @Input() defaultState: MetricPaletteType = 'success';
    @Input() emptyState: MetricPaletteType = 'dark';
    @Input() height!: string;
    @Input() width!: string;
    @Input() label!: string;
    @Input() overall!: string;
    @Input() metric: IMetric[] = [];
    @Input() percentage = false;
    @ViewChild(BaseChartDirective) chart!: BaseChartDirective;

    public ringsChartOptions: ChartConfiguration<'doughnut'>['options'] = {
        cutout: this.cutout,
        responsive: true,
        animation: false,
        plugins: {
            tooltip: { enabled: false },
            legend: { display: false },
        },
    };

    public ringsChartData: ChartConfiguration<'doughnut'>['data'] = {
        datasets: [],
    };

    public ringsChartType: ChartConfiguration<'doughnut'>['type'] = 'doughnut';

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes['metric'] ||
            changes['overall'] ||
            changes['width'] ||
            changes['height']
        ) {
            this.updateChartData();
            this.chart?.chart?.update();
        }
    }

    updateChartData() {
        this.ringsChartData.datasets = this.metric.flatMap((value, index) => {
            const state = findStateForMetric(
                value,
                this.defaultState,
                this.emptyState,
            );

            const colour = this[state as keyof this] as string;

            const ring = {
                data: [value.percent, 100 - value.percent],
                backgroundColor: [colour, this.background],
                hoverBackgroundColor: [colour, this.background],
                borderWidth: 0,
                borderRadius: 20,
            };

            const blank = {
                data: [100, 0],
                backgroundColor: ['#FFFFFFFF', '#FFFFFFFF'],
                hoverBackgroundColor: ['#FFFFFFFF', '#FFFFFFFF'],
                borderWidth: 0,
                borderRadius: 0,
                weight: 0.5,
            };

            if (index < this.metric.length - 1) return [ring, blank];
            else return [ring];
        });
    }

    get cutout(): string {
        return `${90 - this.metric.length * 10}%`;
    }
}
