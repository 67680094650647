<div class="container-fluid">
    <div class="row">
        <div class="col-2">
            <div class="rounded-circle user-initials mt-3 ms-2">
                {{ customerName | initials }}
            </div>
        </div>
        <div class="col-10">
            <div class="d-flex flex-column">
                @if (conversation) {
                    <div class="d-flex flex-row">
                        <div class="font-small mt-2 text-secondary">
                            Incoming CLI:
                        </div>
                    </div>
                    <div class="d-flex flex-row align-items-center">
                        <div class="font-largest">
                            {{ conversation.fromNumber }}
                        </div>
                        <div class="font-large text-secondary ms-auto">
                            {{ callTime | formatDuration }}
                        </div>
                    </div>
                }
            </div>
            <div
                class="font-large d-flex justify-content-between align-items-center"
            >
                {{ customerName }}
            </div>
            <div class="d-flex align-items-start mt-3 mb-3">
                <div class="flex-grow-1">
                    @if (conversation) {
                        <div class="d-flex align-items-center">
                            <div class="col-4 text-secondary font-small">
                                Number dialled:
                            </div>
                            <div class="col-8">
                                {{ conversation.dialedNumber }}
                            </div>
                        </div>

                        <div class="d-flex align-items-center">
                            <div class="col-4 text-secondary font-small">
                                Queue name:
                            </div>
                            <div class="col-8">
                                {{ conversation.queueName }}
                            </div>
                        </div>
                    }
                </div>
                <div
                    class="d-flex flex-column align-items-center justify-content-center ms-3 mt-auto mb-auto"
                >
                    <div>
                        <button
                            (click)="endCall()"
                            type="button"
                            class="btn btn-danger rounded-circle p-2"
                        >
                            <i class="fa-2x fa-light fa-phone-hangup"></i>
                        </button>
                        <div class="text-secondary mt-1 font-small text-center">
                            End Call
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div>
    <!-- <telxl-shared-wrap [workItem]="workItem"></telxl-shared-wrap> -->
</div>
