<div class="break-container">
    <div class="mt-2 ms-2 font-sm position-absolute break-text">
        Current status:
    </div>
    <div class="break-details h-100 w-100">
        <div>
            <i
                class="fa-4x fa-light break-text"
                [ngClass]="
                    presence().state === 'Break' ? 'fa-hourglass-end' : 'fa-ban'
                "
            ></i>
        </div>
        <div class="font-3xl break-text mt-3 fw-light">
            {{ presence().label }}
        </div>
        <div class="font-xxl mt-2">
            {{ stateSince | formatDuration }}
            @if (presence().timedBreakExpiresAt) {
                remaining
            }
        </div>
        @if (presence().state === 'Break') {
            <div class="mt-4">
                <telxl-button colour="dark" (buttonClick)="endBreak()">
                    End Break
                </telxl-button>
            </div>
            <div class="w-90 mt-3">
                <hr class="hr-break" />
            </div>
            <div class="container-fluid perf-container">
                <div class="row ms-5 me-5 mt-3">
                    <div class="col-4 text-center">
                        <div class="d-flex flex-column">
                            <div class="font-lg weight-medium mb-3">
                                My Performance -
                                {{ now | date: 'dd / MM / yyyy' }}
                            </div>
                            <div class="mt-1 mb-1">
                                <span class="font-sm">Agent:</span>
                                <span class="ms-1 weight-medium">
                                    {{ user.name }}
                                </span>
                            </div>
                            <div
                                class="d-flex align-items-center justify-content-center mt-4"
                            >
                                <telxl-doughnut
                                    [metric]="
                                        agent()!.statistics.tasks
                                            .averageHandlingTimeInSeconds.metric
                                    "
                                    height="200px"
                                    [content]="
                                        DoughnutItems([
                                            {
                                                value: 'AHT',
                                                class: 'font-lg weight-medium',
                                                showPercentage: false,
                                            },
                                            {
                                                value:
                                                    secondsToDuration(
                                                        agent()!.statistics
                                                            .tasks
                                                            .averageHandlingTimeInSeconds
                                                            .value
                                                    )
                                                    | formatDuration
                                                        : '[dd]:hh:mm:ss',
                                                class: 'font-xxl weight-medium',
                                                showPercentage: false,
                                            },
                                            {
                                                value: agent()!.statistics.tasks.averageHandlingTimeInSeconds.metric.percent.toString(),
                                                class: 'font-xxl weight-medium',
                                                showPercentage: true,
                                            },
                                        ])
                                    "
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-4 text-center">
                        <div class="d-flex flex-column">
                            <div class="font-lg weight-medium mb-3">
                                Team Performance -
                                {{ now | date: 'dd / MM / yyyy' }}
                            </div>
                            <div>
                                <div class="dropdown">
                                    <span class="font-sm me-2">Team name:</span>
                                    <button
                                        class="btn btn-dropdown-transparent dropdown-toggle"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                    >
                                        {{ selectedTeam }}
                                    </button>
                                    <ul
                                        class="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton"
                                    >
                                        @for (team of teams(); track team.id) {
                                            <li (click)="onTeamChange(team)">
                                                <button
                                                    class="dropdown-item"
                                                    type="button"
                                                >
                                                    {{ team.name }}
                                                </button>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div
                                class="d-flex align-items-center justify-content-center mt-4"
                            >
                                <telxl-doughnut
                                    [metric]="
                                        team()!.statistics.tasks
                                            .averageHandlingTimeInSeconds.metric
                                    "
                                    height="200px"
                                    [content]="
                                        DoughnutItems([
                                            {
                                                value: 'AHT',
                                                class: 'font-lg weight-medium',
                                                showPercentage: false,
                                            },
                                            {
                                                value:
                                                    secondsToDuration(
                                                        team()!.statistics.tasks
                                                            .averageHandlingTimeInSeconds
                                                            .value
                                                    )
                                                    | formatDuration
                                                        : '[dd]:hh:mm:ss',
                                                class: 'font-xxl weight-medium',
                                                showPercentage: false,
                                            },
                                            {
                                                value: team()!.statistics.tasks.averageHandlingTimeInSeconds.metric.percent.toString(),
                                                class: 'font-xxl weight-medium',
                                                showPercentage: true,
                                            },
                                        ])
                                    "
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-4 text-center">
                        <div class="d-flex flex-column">
                            <div class="font-lg weight-medium mb-3">
                                Queue Performance -
                                {{ now | date: 'dd / MM / yyyy' }}
                            </div>
                            <div class="dropdown">
                                <span class="font-sm me-2">Queue name:</span>
                                <button
                                    class="btn btn-dropdown-transparent dropdown-toggle"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                >
                                    {{ selectedQueue }}
                                </button>
                                <ul
                                    class="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                >
                                    @for (queue of queues(); track queue.id) {
                                        <li (click)="onQueueChange(queue)">
                                            <button
                                                class="dropdown-item"
                                                type="button"
                                            >
                                                {{ queue.name }}
                                            </button>
                                        </li>
                                    }
                                </ul>
                            </div>
                            <div
                                class="d-flex align-items-center justify-content-center mt-4"
                            >
                                <telxl-doughnut
                                    [metric]="
                                        currentQueueStatistics
                                            .answeredWithinSLAPercentage.metric
                                    "
                                    height="200px"
                                    [content]="
                                        DoughnutItems([
                                            {
                                                value: 'SLA',
                                                class: 'font-xxl weight-medium',
                                                showPercentage: false,
                                            },
                                            {
                                                value: currentQueueStatistics.answeredWithinSLAPercentage.metric.percent.toString(),
                                                class: 'font-xxl weight-medium',
                                                showPercentage: true,
                                            },
                                        ])
                                    "
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>
</div>
