import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';

/**
 * Component to display progress
 *
 * @selector stacked-progressbar
 * @standalone true
 * @component ProgressComponent
 * @imports CommonModule
 *
 * @Input states - Array of progress states.
 *
 * Usage:
 * <stacked-progressbar [states]="data"></stacked-progressbar>
 *
 */
export interface IStackedProgress {
    value: number;
    class: string;
}

@Component({
    selector: 'telxl-stacked-progressbar',
    standalone: true,
    imports: [CommonModule, NgbProgressbarModule],
    templateUrl: './stacked-progress.component.html',
    styleUrl: './stacked-progress.component.scss',
})
export class TelXLStackedProgressComponent {
    @Input({ required: true }) states!: IStackedProgress[];
    @Input({ required: true }) font!: string;
    @Input({ required: true }) weight!: string;
    @Input() showLabels = false;
}
