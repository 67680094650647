import { of, tap } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggerService } from './../../services';
import { catchError } from 'rxjs/operators';
import { environment } from './../../environments';

@Injectable({
    providedIn: 'root',
})
export class DirectoryApiService {
    constructor(
        private logger: LoggerService,
        private http: HttpClient,
    ) {}

    getPhoto() {
        return this.http
            .get(`${environment.directoryUrl}/me/photo`, {
                responseType: 'blob',
            })
            .pipe(
                tap(photo => {
                    if (photo) {
                        this.logger.debug(
                            'Directory Service (Get Photo) -> Successful',
                            photo,
                        );
                    } else {
                        this.logger.info('User needs to add a photo');
                    }
                }),
                catchError(error => {
                    this.logger.info('User needs to add a photo', error);

                    return of(null);
                }),
            );
    }
}
