export const breakpoints = {
    xs: '(max-width: 575.98px)',
    sm: '(min-width: 576px) and (max-width: 767.98px)',
    md: '(min-width: 768px) and (max-width: 991.98px)',
    lg: '(min-width: 992px) and (max-width: 1399.98px)',
    xl: '(min-width: 1400px) and (max-width: 1799.98px)',
    xxl: '(min-width: 1800px)',
};

export type BootstrapBreakPoints =
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | 'xxl'
    | 'unknown';

export const detectBreakpoint = (): BootstrapBreakPoints => {
    for (const [key, mediaQuery] of Object.entries(breakpoints)) {
        if (window.matchMedia(mediaQuery).matches) {
            return key as BootstrapBreakPoints;
        }
    }

    return 'unknown';
};
