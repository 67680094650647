<nav class="pagination" aria-label="Pagination Navigation">
    <button
        (click)="prevPage()"
        [disabled]="currentPage === 1"
        aria-label="Go to previous page"
    >
        &lt;
    </button>

    <span aria-live="polite" aria-atomic="true">
        Page {{ currentPage }} of {{ totalPages }}
    </span>

    <button
        (click)="nextPage()"
        [disabled]="currentPage === totalPages"
        aria-label="Go to next page"
    >
        &gt;
    </button>
</nav>
