<div class="modal-header">
    <i class="fa-lg fa-circle-exclamation fa-solid me-4"></i>
    <h4 class="modal-title">{{ notification.title }}</h4>
</div>
<div class="modal-body pt-0 pb-0">
    <p>{{ notification.description }}</p>
</div>
<div class="modal-footer pt-1">
    <telxl-button
        colour="secondary"
        [outline]="true"
        class="ps-5 pe-5 pt-1 pb-1"
        (buttonClick)="dismissNotification(notification)"
        [disabled]="!enabled"
    >
        Reject
    </telxl-button>
    <telxl-button
        colour="dark"
        class="ps-5 pe-5 pt-1 pb-1"
        (buttonClick)="actionNotification(notification)"
        [disabled]="!enabled"
    >
        Accept
        @if (hasExpiry) {
            <span class="ms-2 badge bg-secondary">{{ countdown }}</span>
        }
    </telxl-button>
</div>
