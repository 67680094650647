export const ColourPaletteTypes = [
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
    'dropdown',
] as const;
export type ColourPaletteType = (typeof ColourPaletteTypes)[number];

export const SizeTypes = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'] as const;
export type SizeType = (typeof SizeTypes)[number];

export const MetricPaletteTypes = [
    'success',
    'warning',
    'danger',
    'primary',
    'socials',
    'email',
    'webchat',
    'dark',
] as const;
export type MetricPaletteType = (typeof MetricPaletteTypes)[number];
