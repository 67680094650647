<div
    class="d-flex flex-row justify-content-between font-sm w-100 text-primary pb-3"
>
    <span>
        {{ currentPage === 1 ? 'Top' : 'Next' }} {{ itemsPerPage }} Break
        Reasons
    </span>
    <telxl-pagination
        [currentPage]="currentPage"
        [totalPages]="totalPages"
        (pageChanged)="changePage($event)"
    />
</div>
@for (
    breakReason of breakReasonsArray;
    track breakReason.name;
    let idx = $index
) {
    @if (showBreakReason(idx)) {
        <div class="row mb-3">
            <div class="col-9 col-xxl-10">
                <telxl-progressbar
                    [label]="breakReason.name"
                    orientation="horizontal"
                    [overall]="breakReason.percentage.toString()"
                    [metric]="{
                        percent: breakReason.percentage,
                        states: [
                            {
                                value: 0,
                                state: 'primary',
                            },
                        ],
                    }"
                    defaultState="primary"
                    emphasis="light"
                    [percentage]="true"
                />
            </div>
            <div
                class="col-3 col-xxl-2 font-sm d-flex align-items-center justify-content-center"
            >
                ({{
                    secondsToDuration(breakReason.value)
                        | formatDuration: '[dd]:hh:mm:ss'
                }})
            </div>
        </div>
    }
}
