import { CommonModule } from '@angular/common';
import { Component, Signal, computed, inject } from '@angular/core';
import { TelXLButtonComponent } from '@dxp/elements';
import {
    INotificationModel,
    NotificationActionType,
    NotificationType,
} from '@dxp/shared/models';
import { NotificationHubService } from '@dxp/shared/signalr';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-shell-notification-panel',
    imports: [CommonModule, TelXLButtonComponent],
    standalone: true,
    templateUrl: './notification-panel.component.html',
    styleUrl: './notification-panel.component.scss',
})
export class NotificationPanelComponent {
    private _notifications: Signal<INotificationModel[]>;

    tier3 = computed<INotificationModel[]>(() =>
        this._notifications().filter(n => n.type === NotificationType.Tier3),
    );

    activeOffcanvas = inject(NgbActiveOffcanvas);

    constructor(private notificationHubService: NotificationHubService) {
        this._notifications = notificationHubService.notifications;
    }

    dismissNotification(notification: INotificationModel) {
        this.notificationHubService.markNotificationAs(
            notification,
            NotificationActionType.Dismissed,
        );
    }

    actionNotification(notification: INotificationModel) {
        this.notificationHubService.markNotificationAs(
            notification,
            NotificationActionType.Actioned,
        );
    }

    toggleNotificationPinned(notification: INotificationModel) {
        if (notification.pinned)
            this.notificationHubService.markNotificationAs(
                notification,
                NotificationActionType.Unpinned,
            );
        else
            this.notificationHubService.markNotificationAs(
                notification,
                NotificationActionType.Pinned,
            );
    }
}
