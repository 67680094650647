import { MetricPaletteType } from '../elements/elements';

export interface IMetricState {
    value: number;
    state: MetricPaletteType;
}

export interface IMetric {
    percent: number;
    states: IMetricState[];
}

export interface IValueWithMetric {
    value: number;
    metric: IMetric;
}

export interface IDisplayWithMetric {
    value: string;
    metric: IMetric;
}
