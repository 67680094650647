import { Injectable, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class TelXLModalService {
  private modalRef!: NgbModalRef;

  constructor(private modalService: NgbModal) {}

  open(template: TemplateRef<any>, options: any = {}) {
    this.modalRef = this.modalService.open(template, options);
  }

  close() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  dismiss() {
    if (this.modalRef) {
      this.modalRef.dismiss();
    }
  }
}
