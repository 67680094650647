import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    ElementRef,
    NgZone,
    OnDestroy,
    OnInit,
    Signal,
    ViewChild,
    ViewContainerRef,
    computed,
    effect,
    signal,
} from '@angular/core';
import {
    AgentService,
    SettingsService,
    UserService,
} from '@dxp/shared/services';
import { Duration, format, intervalToDuration } from 'date-fns';
import { IIncomingTask, IUserPresence, MediaType } from '@dxp/shared/models';
import { IWorkItem, TaskOrder } from '@dxp/shared/models';
import { Subscription, interval } from 'rxjs';
import { animate, style, transition, trigger } from '@angular/animations';

import { CommonModule } from '@angular/common';
import { FormatDurationPipe } from '@dxp/shared/pipes';
import { TaskBeltItemComponent } from './../item/task-belt-item.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { VoiceNotificationComponent } from '@dxp/voice';

@Component({
    selector: 'app-shell-task-belt',
    standalone: true,
    imports: [CommonModule, FormatDurationPipe, TaskBeltItemComponent],
    templateUrl: './task-belt.component.html',
    styleUrl: './task-belt.component.scss',
    animations: [
        trigger('dropInOut', [
            transition(':enter', [
                style({ opacity: 0, transform: '{{transformStart}}' }),
                animate(
                    '0.5s ease-out',
                    style({ opacity: 1, transform: 'translateY(0)' }),
                ),
            ]),
            transition(':leave', [
                style({ opacity: 1, transform: 'translateY(0)' }),
                animate(
                    '0.5s ease-in',
                    style({ opacity: 0, transform: 'translateY(20px)' }),
                ),
            ]),
        ]),
    ],
})
export class TaskBeltComponent implements AfterViewInit, OnDestroy, OnInit {
    private readonly mediaTypeMappings: Record<
        MediaType | 'default',
        {
            icon: string;
            selectedIcon: string;
            class: string;
            order: TaskOrder;
            navigation: string;
        }
    > = {
        Voice: {
            icon: 'fa-solid fa-phone task-phone',
            selectedIcon: 'fa-solid fa-phone task-phone',
            class: 'selected-phone',
            order: TaskOrder.Voice,
            navigation: 'comms/voice',
        },
        Webchat: {
            icon: 'fa-light fa-message task-chat',
            selectedIcon: 'fa-solid fa-message task-chat',
            class: 'selected-chat',
            order: TaskOrder.Webchat,
            navigation: 'comms/chat',
        },
        Messaging: {
            icon: 'fa-light fa-message task-chat',
            selectedIcon: 'fa-solid fa-message task-chat',
            class: 'selected-chat',
            order: TaskOrder.Messaging,
            navigation: 'comms/chat',
        },
        Email: {
            icon: 'fa-light fa-envelope task-email',
            selectedIcon: 'fa-solid fa-envelope task-email',
            class: 'selected-email',
            order: TaskOrder.Email,
            navigation: 'comms/email',
        },
        default: {
            icon: 'fa-light fa-share-nodes task-social',
            selectedIcon: 'fa-solid fa-share-nodes task-social',
            class: 'selected-social',
            order: TaskOrder.Unknown,
            navigation: 'comms/chat',
        },
    };

    @ViewChild('taskBelt') taskBeltRef!: ElementRef<HTMLDivElement>;
    @ViewChild('voice', { read: ViewContainerRef, static: true })
    voice!: ViewContainerRef;
    private voiceRef?: ComponentRef<VoiceNotificationComponent>;

    delayTimeout: any;
    delayedTasksExist = false;
    displayedTasks = signal<IIncomingTask[]>([]);
    intervalSubscription: Subscription | undefined;
    now = new Date();
    presence: Signal<IUserPresence>;
    selectedItem: string | null = null;
    tasksExist = computed(() => this.displayedTasks().length > 0);
    timer: any;
    workItems!: Signal<IWorkItem[]>;
    displayVoiceNotification = false;

    constructor(
        private userService: UserService,
        private cdr: ChangeDetectorRef,
        private ngZone: NgZone,
        private settingsService: SettingsService,
        private agentService: AgentService,
    ) {
        this.presence = this.userService.presence;

        this.workItems = toSignal(this.agentService.agentWorkItems$, {
            initialValue: [] as IWorkItem[],
        });

        effect(() => {
            if (this.tasksExist()) {
                this.delayedTasksExist = true;
                this.cdr.detectChanges();
                if (this.delayTimeout) clearTimeout(this.delayTimeout);
            } else {
                this.delayTimeout = setTimeout(() => {
                    this.delayedTasksExist = false;
                    this.cdr.detectChanges();
                }, 500);
            }
        });
        effect(
            () => {
                this.syncTasksWithWorkItems();
            },
            { allowSignalWrites: true },
        );
    }

    get stateSince(): Duration {
        return intervalToDuration({
            start: new Date(this.presence().stateSince),
            end: this.now,
        });
    }

    acceptIncomingTask() {
        const workItemsArray = this.workItems();

        Object.values(workItemsArray).forEach(task => {
            if (!this.checkDuplicateTask(task)) {
                const newTask: IIncomingTask = {
                    order:
                        this.mediaTypeMappings[task.primaryMediaType]?.order ||
                        this.mediaTypeMappings.default.order,
                    workItemId: task.workItemId,
                    conversationId: task.primaryConversationId,
                    mediaType: task.primaryMediaType,
                    channelType: task.primaryChannelType,
                    createdAt: format(
                        new Date(task.createdAt),
                        'hh:mma',
                    ).toLowerCase(),
                    queueName: this.queueName(task),
                    createdAtActual: new Date(task.createdAt),
                    icon:
                        this.mediaTypeMappings[task.primaryMediaType]?.icon ||
                        this.mediaTypeMappings.default.icon,
                    iconSelected:
                        this.mediaTypeMappings[task.primaryMediaType]
                            ?.selectedIcon ||
                        this.mediaTypeMappings.default.selectedIcon,
                    selected: false,
                    classSelected:
                        this.mediaTypeMappings[task.primaryMediaType]?.class ||
                        this.mediaTypeMappings.default.class,
                    navigation:
                        this.mediaTypeMappings[task.primaryMediaType]
                            ?.navigation ||
                        this.mediaTypeMappings.default.navigation,
                    workItemState: task.workItemState,
                    closureCodes: task.usableClosureCodes,
                    converations: task.conversations,
                };

                this.displayedTasks.update(tasks => [...tasks, newTask]);
                this.displayedTasks().sort((task1, task2) => {
                    if (task1.order === task2.order) {
                        return (
                            task1.createdAtActual.getTime() -
                            task2.createdAtActual.getTime()
                        );
                    }
                    return task1.order - task2.order;
                });

                if (task.primaryMediaType === 'Voice') {
                    this.showVoiceNotification(newTask);
                }

                this.updateTaskStatus();
            }
        });
    }

    checkDuplicateTask(task: IWorkItem): boolean {
        return this.displayedTasks().some(
            value => value.workItemId === task.workItemId,
        );
    }

    deselectOthers(workItemId: string) {
        this.selectedItem =
            this.selectedItem === workItemId ? null : workItemId;
        if (this.selectedItem !== workItemId) {
            this.selectedItem = workItemId;
        }
    }

    isSelected(workItemId: string): boolean {
        return this.selectedItem === workItemId;
    }

    ngAfterViewInit() {
        this.updateTaskStatus();
    }

    ngOnDestroy(): void {
        if (this.intervalSubscription) {
            this.intervalSubscription.unsubscribe();
        }
        clearTimeout(this.delayTimeout);
    }

    ngOnInit(): void {
        this.ngZone.runOutsideAngular(() => {
            this.intervalSubscription = interval(1000).subscribe(() => {
                this.now = new Date();

                this.ngZone.run(() => {
                    this.cdr.detectChanges();
                });
            });
        });
    }

    queueName(task: IWorkItem): string {
        if (task.conversations) {
            const conversation = task.conversations.find(
                c => c.conversationId === task.primaryConversationId,
            );
            if (conversation && conversation.queueName) {
                return conversation.queueName;
            }
        }
        return 'Unknown Queue';
    }

    removeTask() {
        const currentTasks = this.displayedTasks();
        let tskid = '';

        currentTasks.forEach(task => {
            const existsInWorkItems = this.taskExists(task.workItemId);
            if (!existsInWorkItems) {
                tskid = task.workItemId;
            }
        });

        const updatedItems = currentTasks.filter(
            item => item.workItemId !== tskid,
        );

        this.displayedTasks.set(updatedItems);
    }

    selectTimeout(mediaType: string): number {
        switch (mediaType) {
            case 'Voice':
                return 20;
            case 'Webchat':
                return this.settingsService.getRona('chat');
            case 'Messaging':
                return 120;
            case 'Email':
                return this.settingsService.getRona('email');
            default:
                return 120;
        }
    }

    syncTasksWithWorkItems(): void {
        let workItemsArray = this.workItems();
        if (
            Object.values(workItemsArray).length < this.displayedTasks().length
        ) {
            this.removeTask();
        }
        workItemsArray = this.workItems();
        if (
            Object.values(workItemsArray).length > this.displayedTasks().length
        ) {
            this.acceptIncomingTask();
        }

        this.updateTaskStatus();
    }

    taskExists(workItemId: string): boolean {
        return Object.values(this.workItems()).some(
            value => value.workItemId === workItemId,
        );
    }

    updateTaskStatus() {
        const taskExistValue = this.tasksExist();
        if (taskExistValue) {
            this.cdr.detectChanges();
        }
    }

    showVoiceNotification(task: IIncomingTask) {
        if (!this.voiceRef) {
            this.voice.clear();
            this.voiceRef = this.voice.createComponent(
                VoiceNotificationComponent,
            );
            this.voiceRef.instance.close.subscribe(() => {
                this.closeVoiceNotification();
            });

            this.voiceRef.instance.workItemId = task.workItemId;
            this.voiceRef.instance.navigateTo = task.navigation;
            this.voiceRef.instance.timeout = this.selectTimeout(task.mediaType);
        }
    }
    closeVoiceNotification() {
        if (this.voiceRef) {
            this.voiceRef.destroy();
            this.voiceRef = undefined;
        }
    }
}
