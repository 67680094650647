<div
    style="display: flex; justify-content: space-evenly; align-items: center"
    [style.height]="height"
    [style.width]="width"
>
    <div class="-m-5">
        <div class="font-sm weight-medium p-0 m-0">{{ label }}</div>
        <div class="font-xxl weight-medium p-0 m-0">
            {{ overall }}
            <span class="font-sm weight-heavy">
                @if (percentage) {
                    %
                }
            </span>
        </div>
    </div>
    <canvas
        baseChart
        [data]="ringsChartData"
        [options]="ringsChartOptions"
        [type]="ringsChartType"
    ></canvas>
</div>
