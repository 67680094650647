<button
    [routerLink]="routerLink"
    [ngClass]="buttonClass"
    [disabled]="disabled"
    [class.active]="active"
    [type]="type"
    [attr.aria-label]="aria"
    (click)="onClick($event)"
>
    <ng-content></ng-content>
</button>
