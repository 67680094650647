import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    Output,
} from '@angular/core';

@Component({
    selector: 'telxl-pagination',
    standalone: true,
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
})
export class TelXLPaginationComponent {
    @Input() currentPage = 1;
    @Input() totalPages = 1;
    @Output() pageChanged = new EventEmitter<number>();

    @HostListener('window:keydown.arrowleft', ['$event'])
    onArrowLeft(event: KeyboardEvent) {
        event.preventDefault();
        this.prevPage();
    }

    @HostListener('window:keydown.arrowright', ['$event'])
    onArrowRight(event: KeyboardEvent) {
        event.preventDefault();
        this.nextPage();
    }

    nextPage() {
        if (this.currentPage < this.totalPages) {
            this.pageChanged.emit(this.currentPage + 1);
        }
    }
    prevPage() {
        if (this.currentPage > 1) {
            this.pageChanged.emit(this.currentPage - 1);
        }
    }
}
