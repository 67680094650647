import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
    DoughnutItems,
    TelXLDoughnutComponent,
    TelXLPaginationComponent,
} from '@dxp/elements';
import { leftSplit, recordToArray, rightSplit } from '@dxp/shared/helpers';

@Component({
    selector: 'telxl-shared-disposition-codes-panel',
    standalone: true,
    imports: [CommonModule, TelXLDoughnutComponent, TelXLPaginationComponent],
    templateUrl: './disposition-codes-panel.component.html',
    styleUrl: './disposition-codes-panel.component.scss',
})
export class TelXLDispositionCodesComponent {
    itemsPerPage = 5;
    currentPage = 1;
    selectedDispositionCodeIndex = 0;
    recordToArray = recordToArray;
    DoughnutItems = DoughnutItems;

    @Input({ required: true }) dispositioncodes!: Record<
        string,
        { value: number; percentage: number }
    >;

    @Input() selectClass: 'highlight' | 'shadowing' = 'highlight';
    @Input({ required: true }) chartSize!: string;
    @Input({ required: true }) chartSplit!: number;

    selectDispositionCode(dispositionCodeIndex: number) {
        this.selectedDispositionCodeIndex = dispositionCodeIndex;
    }

    changePage(newPage: number) {
        this.currentPage = newPage;
        this.selectedDispositionCodeIndex =
            (this.currentPage - 1) * this.itemsPerPage;
    }

    showDispositionCode(index: number): boolean {
        const low = (this.currentPage - 1) * this.itemsPerPage;
        const high = low + this.itemsPerPage - 1;

        return index >= low && index <= high;
    }

    get dispositionCodesArray(): {
        name: string;
        value: number;
        percentage: number;
    }[] {
        return recordToArray(this.dispositioncodes).sort(
            (a, b) => b.percentage - a.percentage,
        );
    }

    get percentage(): number {
        if (this.dispositionCodesArray.length === 0) {
            return 0;
        }

        return (
            this.dispositionCodesArray[this.selectedDispositionCodeIndex]
                ?.percentage ?? 0
        );
    }

    get times(): number {
        if (this.dispositionCodesArray.length === 0) {
            return 0;
        }

        return (
            this.dispositionCodesArray[this.selectedDispositionCodeIndex]
                ?.value ?? 0
        );
    }

    get totalPages() {
        const pages = Math.ceil(
            this.dispositionCodesArray.length / this.itemsPerPage,
        );
        return pages > 0 ? pages : 1;
    }

    get leftSplit(): number {
        return leftSplit(this.chartSplit);
    }

    get rightSplit(): number {
        return rightSplit(this.leftSplit);
    }

    get splitchart(): string {
        return this.leftSplit === 0 ? 'd-none' : `w-${this.leftSplit}`;
    }
}
