import { CommonModule } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormatDurationPipe } from '@dxp/shared/pipes';
import { Duration, intervalToDuration } from 'date-fns';
import { interval, Subscription } from 'rxjs';

@Component({
    selector: 'telxl-shared-task-progress',
    standalone: true,
    templateUrl: './task-progress.component.html',
    styleUrl: './task-progress.component.scss',
    imports: [CommonModule, FormatDurationPipe],
})
export class TelXLTaskProgressComponent implements OnInit, OnChanges {
    @Input() totalSeconds = 0;
    @Input() createdAtActual: Date | undefined;
    @Input() stop = false;
    @Input() isTask = false;
    @Input() wrapRestart!: Date;
    @Output() wrapTimedOut = new EventEmitter<boolean>();

    progress = 100;
    remainingTime = 0;
    wrapEnds: Date = new Date();
    intervalSubscription: Subscription | undefined;

    constructor(
        private cdr: ChangeDetectorRef,
        private ngZone: NgZone,
    ) {}

    ngOnInit(): void {
        this.initializeTimer();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['stop'] && !changes['stop'].firstChange) {
            this.stopRequested(changes['stop'].currentValue);
        }
        if (
            changes['createdAtActual'] &&
            !changes['createdAtActual'].firstChange
        ) {
            this.restartTimer();
        }
    }

    initializeTimer(): void {
        if (this.createdAtActual) {
            this.wrapEnds.setSeconds(
                this.createdAtActual.getSeconds() + this.totalSeconds,
            );
            this.remainingTime =
                this.totalSeconds -
                Math.floor(
                    (new Date().getTime() - this.createdAtActual.getTime()) /
                        1000,
                ) -
                1;

            this.startTimer();
        }
    }

    stopRequested(request: boolean): void {
        if (request) {
            this.remainingTime = 0;
        }
    }

    startTimer() {
        this.ngZone.runOutsideAngular(() => {
            this.intervalSubscription = interval(1000).subscribe(() => {
                this.remainingTime--;
                if (this.remainingTime <= 0) {
                    this.intervalSubscription?.unsubscribe();
                    this.wrapTimedOut.emit(true);
                }
                this.progress = (this.remainingTime / this.totalSeconds) * 100;
                this.ngZone.run(() => {
                    this.cdr.detectChanges();
                });
            });
        });
    }

    get remainingWrap(): Duration {
        return intervalToDuration({
            start: new Date(),
            end: new Date(this.wrapEnds),
        });
    }

    restartTimer(): void {
        this.createdAtActual = this.wrapRestart;
        this.intervalSubscription?.unsubscribe();
        this.wrapEnds = new Date();
        this.initializeTimer();
    }
}
