import {
    Component,
    computed,
    Signal,
    OnDestroy,
    effect,
    OnInit,
    NgZone,
    ChangeDetectorRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ICallState, IWorkItem, IWrap } from '@dxp/shared/models';
import { IConversation } from '@dxp/shared/models';
import { SettingsService } from '@dxp/shared/services';
import { SipService } from '@dxp/shared/services';
import { VoiceApiService } from '@dxp/shared/api';
import { ICustomerDetails } from '@dxp/shared/models';
import { CustomerApiService } from '@dxp/shared/api';
import { InitialsPipe } from '@dxp/shared/pipes';
import { Duration, intervalToDuration } from 'date-fns';
import { FormatDurationPipe } from '@dxp/shared/pipes';
import { Subscription, interval, map } from 'rxjs';
import { AgentService } from '@dxp/shared/services';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'voice-shell',
    standalone: true,
    imports: [CommonModule, FormsModule, InitialsPipe, FormatDurationPipe],
    templateUrl: './voice.component.html',
    styleUrl: './voice.component.scss',
})
export class VoiceComponent implements OnInit, OnDestroy {
    taskClosing = false;
    workItem!: Signal<IWorkItem>;
    initialWrapLength = 0;
    wrapDetails: IWrap | undefined;
    callEnded = false;

    workItemId: string | null = null;
    intervalSubscription: Subscription | undefined;
    now: Date = new Date();

    customerName = 'Unknown customer';
    callAccepted: Date | undefined;

    customer = computed<ICustomerDetails | null>(() =>
        this.customerApiService.customer(),
    );

    conversation: IConversation | undefined;

    constructor(
        private sipService: SipService,
        private settingsService: SettingsService,
        private voiceApiService: VoiceApiService,
        private customerApiService: CustomerApiService,
        private agentService: AgentService,
        private cdr: ChangeDetectorRef,
        private ngZone: NgZone,
        private route: ActivatedRoute,
    ) {
        this.workItemId = this.route.snapshot.paramMap.get('workItemId');

        const workItem$ = this.agentService.agentWorkItems$.pipe(
            map(
                wi =>
                    wi.find(w => w.workItemId === this.workItemId) as IWorkItem,
            ),
        );
        this.workItem = toSignal(workItem$, { initialValue: {} as IWorkItem });

        effect(() => {
            const customer = this.customer();
            if (customer) {
                this.customerName = customer.name;
                this.cdr.detectChanges();
            }
        });

        effect(() => {
            const workItem = this.workItem();
            if (workItem) {
                if (workItem.customerId) {
                    this.customerApiService.getCustomer(workItem.customerId);
                }
            }
        });
    }

    ngOnInit() {
        this.wrapDetails = this.settingsService.getWrapDetails();

        const currentWorkItem = this.workItem();
        if (currentWorkItem) {
            if (!this.conversation) {
                this.conversation = this.workItem().conversations.find(
                    c =>
                        c.conversationId ===
                        this.workItem().primaryConversationId,
                ) as IConversation;
            }
        }

        this.ngZone.runOutsideAngular(() => {
            this.intervalSubscription = interval(1000).subscribe(() => {
                this.now = new Date();

                this.ngZone.run(() => {
                    this.cdr.detectChanges();
                });
            });
        });
    }

    ngOnDestroy() {
        if (this.intervalSubscription) {
            this.intervalSubscription.unsubscribe();
        }
    }

    endCall(): void {
        if (this.workItem()) {
            this.intervalSubscription?.unsubscribe();
            const params: ICallState = {
                conversationId: this.workItem().primaryConversationId as string,
                callState: 'Hangup',
            };
            this.voiceApiService.changeCallState(params);
        }
        this.sipService.hangUp();
    }

    get callTime(): Duration {
        if (this.workItem()) {
            return intervalToDuration({
                start: this.workItem().lastStateChangeDate,
                end: new Date(),
            });
        }

        return {
            hours: 0,
            minutes: 0,
            seconds: 0,
        };
    }
}
