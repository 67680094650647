import { environment } from '../environments/environment';

export const getColourValue = (key: string): string => {
    return getComputedStyle(document.documentElement).getPropertyValue(key);
};

export const DisplayTypes = ['dashboard', 'performance', 'chat'] as const;
export type DisplayType = (typeof DisplayTypes)[number];

export const ViewTypes = ['standard', 'expanded'] as const;
export type ViewType = (typeof ViewTypes)[number];

export const recalculateHeight = (
    view: DisplayType,
    display: ViewType,
): string => {
    return `calc(100vh - ${environment.resize[view][display]}px)`;
};
