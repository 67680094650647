<div class="modal-header">
    <i class="fa-lg fa-solid me-4" [ngClass]="icon"></i>
    <h4 class="modal-title weight-medium">{{ title }}</h4>
</div>
<div class="modal-body font-sm pt-0 pb-0">
    <p>{{ message }}</p>
</div>
<div class="modal-footer pt-1 justify-content-center">
    @for (button of buttons; track button.label) {
        <button
            class="btn font-sm"
            type="button"
            [ngClass]="button.cssClass"
            (click)="handleButtonClick(button.returnValue)"
        >
            {{ button.label }}
        </button>
    }
</div>
