import { CommonModule } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    computed,
    effect,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    Signal,
    NgZone,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
    ICallState,
    IConversation,
    ICustomerDetails,
    IWorkItem,
} from '@dxp/shared/models';
import { AgentService } from '@dxp/shared/services';
import { interval, map, Subscription } from 'rxjs';
import { CustomerApiService } from '@dxp/shared/api';
import { VoiceCallInfoComponent } from '../voice-call-info/voice-call-info.component';
import { SipService } from '@dxp/shared/services';
import { VoiceApiService } from '@dxp/shared/api';
import { Router } from '@angular/router';
import { FormatDurationPipe } from '@dxp/shared/pipes';
import { Duration, intervalToDuration } from 'date-fns';

@Component({
    selector: 'voice-notification',
    standalone: true,
    imports: [CommonModule, VoiceCallInfoComponent, FormatDurationPipe],
    templateUrl: './voice-notification.component.html',
    styleUrl: './voice-notification.component.scss',
})
export class VoiceNotificationComponent implements OnInit, OnDestroy {
    @Output() close = new EventEmitter<void>();

    workItemId = '';
    workItem!: Signal<IWorkItem>;
    customerName = 'Unknown customer';
    navigateTo = '';
    timeout = 0;
    now = new Date();
    startTimer = new Date();
    private intervalSubscription: Subscription | undefined;

    customer = computed<ICustomerDetails | null>(() =>
        this.customerApiService.customer(),
    );

    conversation: IConversation | undefined;

    constructor(
        private agentService: AgentService,
        private customerApiService: CustomerApiService,
        private cdr: ChangeDetectorRef,
        private sipService: SipService,
        private voiceApiService: VoiceApiService,
        private router: Router,

        private ngZone: NgZone,
    ) {
        const workItem$ = this.agentService.agentWorkItems$.pipe(
            map(
                wi =>
                    wi.find(w => w.workItemId === this.workItemId) as IWorkItem,
            ),
        );
        this.workItem = toSignal(workItem$, { initialValue: {} as IWorkItem });

        effect(() => {
            const customer = this.customer();
            if (customer) {
                this.customerName = customer.name;
                this.cdr.detectChanges();
            }
        });

        effect(() => {
            const workItem = this.workItem();
            if (workItem) {
                if (workItem.customerId) {
                    this.customerApiService.getCustomer(workItem.customerId);
                }
                this.conversation = workItem.conversations.find(
                    c => c.conversationId === workItem.primaryConversationId,
                ) as IConversation;
                if (!this.conversation) {
                    this.close.emit();
                }
            }
        });
    }

    ngOnInit() {
        this.ngZone.runOutsideAngular(() => {
            this.intervalSubscription = interval(1000).subscribe(() => {
                this.now = new Date();
                this.ngZone.run(() => {
                    this.cdr.detectChanges();
                });
            });
        });
        this.startTimer = new Date();
    }

    ngOnDestroy() {
        if (this.intervalSubscription) {
            this.intervalSubscription.unsubscribe();
        }
    }

    onAcceptClick(): void {
        this.sipService.answerCall();
        const params: ICallState = {
            conversationId: this.workItem().primaryConversationId,
            callState: 'Answer',
        };
        this.voiceApiService.changeCallState(params);
        this.router.navigate([this.navigateTo, this.workItem().workItemId]);
        this.close.emit();
    }

    onRejectClick(): void {
        const params: ICallState = {
            conversationId: this.workItem().primaryConversationId,
            callState: 'Hangup',
        };
        this.voiceApiService.changeCallState(params);
        this.sipService.reject();

        this.close.emit();
    }

    get countdown(): Duration {
        return intervalToDuration({
            start: new Date(),
            end: new Date().setSeconds(
                this.startTimer.getSeconds() + this.timeout,
            ),
        });
    }

    get progress(): number {
        const remainingTime =
            this.timeout -
            Math.round((this.now.getTime() - this.startTimer.getTime()) / 1000);
        return (remainingTime / this.timeout) * 100;
    }
}
