export interface ICustomerDetails {
    customerId: string;
    name: string;
    businessUnitId: string;
    title: string;
    firstName: string;
    lastName: string;
    middleName: string;
    picture: string;
    location: string;
    locale: string;
    email: string;
    externalId: string;
}
