import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { Route } from '@angular/router';

export const appRoutes: Route[] = [
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
    {
        path: 'dashboard',
        loadChildren: () =>
            import('@dxp/dashboard').then(m => m.DashboardRoutes),
    },
    {
        path: 'performance',
        loadChildren: () =>
            import('@dxp/performance').then(m => m.PerformanceRoutes),
    },
    {
        path: 'debug',
        loadChildren: () => import('@dxp/debug').then(m => m.DebugRoutes),
    },
    {
        path: 'comms',
        loadChildren: () =>
            import('./communications/communication.routes').then(
                m => m.CommunicationRoutes,
            ),
    },
    {
        path: 'examples',
        loadChildren: () => import('@dxp/examples').then(m => m.ExamplesRoutes),
    },
    {
        path: 'auth-callback',
        component: AuthCallbackComponent,
    },
];
