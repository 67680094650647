import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { findStateForMetric, getColourValue } from '@dxp/shared/helpers';
import { IMetric, MetricPaletteType } from '@dxp/shared/models';
import { ChartConfiguration } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

/**
 * Component to display doughnut chart with centered text.
 * The ring represents a percentage value with a colour code indicating the status.
 *
 * @selector doughnut
 * @standalone true
 * @component DoughnutComponent
 * @imports CommonModule, BaseChartDirective
 *
 * @Input metric - Object conforming to IMetric interface, representing the ring in the chart.
 * @Input content - The inner content
 *
 * Usage:
 * <telxl-doughnut [metric]="data" [content]="<span>100%</span>"></doughnut>
 *
 * Note:
 * A data item contains the percent value of the ring and state change values.
 * The state change value is an array of numbers to set the RAG status.
 *
 * e.g. { percent: 80, state: [{25, 'success'}, {75, 'danger'}]}
 * In this scenario a percent value over 25 will render success state and over 75 will render danger state.
 *
 * e.g. { percent: 30, state: [{50, 'warning'}]}
 * In this scenario a percent value over 50 will render warning state. No red state is configured.
 *
 */
@Component({
    selector: 'telxl-doughnut',
    standalone: true,
    imports: [CommonModule, BaseChartDirective],
    templateUrl: './doughnut.component.html',
    styleUrl: './doughnut.component.scss',
})
export class TelXLDoughnutComponent implements OnChanges, AfterViewInit {
    // @ts-ignore
    private danger = getColourValue('--red-primary');

    // @ts-ignore
    private warning = getColourValue('--yellow-primary');
    // @ts-ignore

    // @ts-ignore
    private success = getColourValue('--green-primary');

    // @ts-ignore
    private dark = getColourValue('--dark-primary');

    // @ts-ignore
    private socials = getColourValue('--purple-primary');

    // @ts-ignore
    private webchat = getColourValue('--webchat-primary');

    // @ts-ignore
    private email = getColourValue('--email-primary');

    // @ts-ignore
    private primary = getColourValue('--blue-primary');
    private background = getColourValue('--background');

    @Input() defaultState: MetricPaletteType = 'success';
    @Input() emptyState: MetricPaletteType = 'dark';
    @Input() metric!: IMetric;
    @Input({ required: true }) height!: string;
    @Input() cutout = '90%';
    @Input() content = '';

    @ViewChild(BaseChartDirective) chart!: BaseChartDirective;

    constructor(private cdr: ChangeDetectorRef) {}

    doughnutChartOptions: ChartConfiguration<'doughnut'>['options'] = {
        cutout: this.cutout,
        responsive: true,
        animation: false,
        maintainAspectRatio: true,
        plugins: {
            tooltip: { enabled: false },
            legend: { display: false },
        },
    };

    doughnutChartData: ChartConfiguration<'doughnut'>['data'] = {
        datasets: [],
    };

    doughnutChartType: ChartConfiguration<'doughnut'>['type'] = 'doughnut';

    ngOnChanges(changes: SimpleChanges) {
        if (changes['cutout'] && this.doughnutChartOptions) {
            this.doughnutChartOptions.cutout = this.cutout;
        }
        if (changes['metric'] || changes['overall'] || changes['height']) {
            this.updateChartData();
            this.chart?.chart?.update();
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.updateChartData();
            this.chart?.chart?.update();
            this.cdr.detectChanges();
        });
    }

    updateChartData() {
        const state = findStateForMetric(
            this.metric,
            this.defaultState,
            this.emptyState,
        );

        const colour = this[state as keyof this] as string;

        this.doughnutChartData.datasets = [
            {
                data: [this.metric.percent, 100 - this.metric.percent],
                backgroundColor: [colour, this.background],
                hoverBackgroundColor: [colour, this.background],
                borderWidth: 0,
                borderRadius: 20,
            },
        ];
    }
}
